import * as atlas from "azure-maps-control";
import '../css/project.css';

// let params = new URLSearchParams(document.location.search);
// let lon = parseFloat(params.get('lon'));
// let lat= parseFloat(params.get('lat'));
let geo = JSON.parse(document.querySelector('#geo').textContent);
let pharmacies = JSON.parse(document.querySelector('#pharmacies').textContent);

let shareButton = document.querySelector('#share-btn');

let map = new atlas.Map('myMap', {
  center: geo.coords,
  zoom: 12,
  minZoom:10,
  language: 'en-US',
  authOptions: {
    authType: 'anonymous',
    clientId: 'f56d1a4b-491e-4f51-a2d1-f1a5b7d164cf',
    getToken: (resolve, reject, map) => {
      const tokenServiceUrl = '/token';
      fetch(tokenServiceUrl).then(r => r.text()).then(token => resolve(token));
    }
  }
});

map.events.add("tokenacquired", function () {
    console.log("token acquired");
});
map.events.add("error", function (err) {
    console.log(JSON.stringify(err.error));
});

map.events.add('ready', () => {
    // let source = new atlas.source.DataSource();
    // map.sources.add(source);
    // let layer = new atlas.layer.SymbolLayer(source, null, {
    //     textOptions: {
    //         textField: ['to-string', ['get', 'id']]
    //     }
    // });
    // map.layers.add(layer);

    for (let i = 0, len = pharmacies.length; i < len; i++) {
        let f = pharmacies[i];
        let marker = new atlas.HtmlMarker({
            color: 'DodgerBlue',
            text: f.id.toString(),
            position: f.loc,
            popup: new atlas.Popup({
                content: `<div style="padding:10px">${f.name}</div>`,
                pixelOffset: [0, -30]
            })
        });
        map.markers.add(marker);
        map.events.add('mouseenter',marker, () => {
            marker.togglePopup();
        });
        map.events.add('mouseleave',marker, () => {
            marker.togglePopup();
        });
    }

    map.setCamera({
        center: geo.coords,
    });
});

if (navigator.geolocation) {
    shareButton.addEventListener('click', () => {
        navigator.geolocation.getCurrentPosition(
            position => {
                // const url = new URL(window.location.origin);
                // url.searchParams.append('lon', position.coords.longitude.toString());
                // url.searchParams.append('lat', position.coords.latitude.toString());
                // window.location = url;
                document.querySelector('#loc-form').reset();
                document.querySelector('#lat').value = position.coords.latitude;
                document.querySelector('#lon').value = position.coords.longitude;
                document.querySelector('#loc-form').submit();
            },
            positionError => {
              document.querySelector('#loc-error').classList.toggle("hidden");
              console.log(positionError)
            })
    });
} else {
    shareButton.disabled = true;
}
